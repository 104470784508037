<template>
  <section class="speakers">
    <div class="speakers__content">
      <h1 class="speakers__title">
          2024 Featured Speakers
      </h1>
      <div class="speakers__cards">
        <div class="speakers__cards--single">
          <button @click="pierce = true" class="speaker-btn">
            <img src="../assets/speakers/Brock.png" alt="Brock Pierce">
            <h2>Brock Pierce</h2>
            <h4>Chairman of the Bitcoin Foundation, Presidential Candidate</h4>
          </button> 
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="pierce" @click="pierce = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="pierce">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Brock.png" alt="Brock Pierce">
                </div>
                <div class="speaker-info">
                  <h2>Brock Pierce</h2>
                  <h3>Chairman of the Bitcoin Foundation, Presidential Candidate</h3>
                  <p>Brock Pierce is a futurist, entrepreneur, philanthropist, and
                  impact investor with an extensive track record of founding,
                  advising and investing in disruptive businesses. He s
                  credited with pioneering the market for digital currency and
                  has raised more than $5B for companies he has founded,
                  opening opportunities for millions of people. Pierce is the
                  Chairman of the Bitcoin Foundation and co-founder of EOS
                  Alliance, Block.one, Blockchain Capital, Tether, and
                  Mastercoin (first ICO).</p>
                </div>
              </div>
              <button @click="pierce = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="pham = true" class="speaker-btn">
            <img src="../assets/speakers/Pham.jpg" alt="Peter Pham">
            <h2>Peter Pham</h2>
            <h4>Science Studio Ventures - Co-Founder<br>Liquid Death, Dollar Shave Club</h4>
          </button> 
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="pham" @click="pham = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="pham">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Pham2.jpg" alt="Peter Pham">
                </div>
                <div class="speaker-info">
                  <h2>Peter Pham</h2>
                  <h3>Science Studio Ventures - Co-Founder<br>Liquid Death, Dollar Shave Club</h3>
                  <p>
                    Peter Pham is the co-founder of Science Studio Ventures.
                    His incubator is behind companies like Dollar Shave Club, DogVacay (merged w/ Rover),
                    Mammoth Media, <a href="https://www.pray.com/" target="_blank">Pray.com</a> and Liquid Death.
                    He's been a seed stage angel investor & advisor to companies like Nextdoor, Kabam, Ring, Tonal,Tala, & Scopely.
                    <br><br>Most recently he co-executive produced the Kanye documentary Jeen-yuhs
                    on Netflix as he continues to invest & incubate in companies that are part of the mainstream culture.
                    As an entrepreneur he was on the founding team of Photobucket which sold to Myspace
                    for $300M in 2007 and was co-founder/CEO of Billshrink/Truaxis which sold to Mastercard.
                  </p>
                </div>
              </div>
              <button @click="pham = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="jon = true" class="speaker-btn">
            <img src="../assets/speakers/Najarian.jpg" alt="Jon “DRJ” Najarian">
            <h2>Jon “DRJ” Najarian</h2>
            <h4>Market Rebellion - Co-Founder</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="jon" @click="jon = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="jon">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Najarian.jpg" alt="Jon “DRJ” Najarian">
                </div>
                <div class="speaker-info">
                  <h2>Jon “DRJ” Najarian</h2>
                  <h3>Market Rebellion - Co-Founder</h3>
                  <p>Jon 'DRJ' Najarian was linebacker for the Chicago Bears before he turned to another
                    kind of a contact sport - trading on the Chicago Board Options Exchange. He became
                    a member of the CBOE, NYSE, CME and CBOT and worked as a floor trader for some
                    25 years. In 1990 he founded Mercury Trading, a market-making firm at the Chicago
                    Board Options Exchange (CBOE), which he sold in 2004 to Citadel, one of the world's
                    largest hedge funds. In 2005 Jon co-founded optionMONSTER and tradeMONSTER,
                    sold a majority stake to private equity firm General Atlantic Partners in 2014 & two years
                    later sold the firm to E*Trade for $750M. Today, he is a host on a variety of mainstream
                    financial news netwoks, a professional investor, money manager, market strategist and
                    venture evangelist. Jon is also a co-host and co-founder of proprietary programming
                    including the popular “Rebels' Edge” streaming show and the “Bulls, Bears &
                    Blockchain” radio show.
                    <br><br>
                    Jon is also chairman of a technology SPAC, an investor and senior advisor to IDI
                    Group, LLC (a multi-family office) and co-founder of Moneta Advisory Partners, LLC, a
                    “venture evangelism” platform that raises awareness of a portfolio of growing public
                    companies.
                  </p>
                </div>
              </div>
              <button @click="jon = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="schiff = true" class="speaker-btn">
            <img src="../assets/speakers/Schiff.jpg" alt="Peter Schiff">
            <h2>Peter Schiff</h2>
            <h4>Euro Pacific Asset Management - Chief Economist & Global Strategist</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="schiff" @click="schiff = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="schiff">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Schiff.jpg" alt="Peter Schiff">
                </div>
                <div class="speaker-info">
                  <h2>Peter Schiff</h2>
                  <h3>Euro Pacific Asset Management - Chief Economist & Global Strategist</h3>
                  <p>
                    Peter began his investment career as a financial consultant with Shearson Lehman Brothers in 1987.
                    A financial professional for over twenty years, he joined Euro Pacific Capital, Inc.
                    (EPC) in 1996 and has served as its President since January 2000.
                    <br><br>
                    Peter Schiff is a widely recognized economic and financial analyst and has appeared frequently on Fox News,
                    Fox Business, CNBC, CNN, and other financial and political news outlets. He achieved national notoriety in
                    2008 as being one of the few economists to have accurately forecast the financial crisis well in advance.
                    Between 2004 and 2006 he had made numerous high-profile statements predicting the bursting of the real
                    estate bubble, significant declines in national real estate prices, the collapse of the mortgage market
                    and the banking sector, the bankruptcy and bailout of Fannie Mae and Freddie Mac.
                    Peter has authored several best-selling books including Crash Proof, Crash Proof 2.0,
                    How and Economy Grows and Why it Crashes, The Little Book of Bull Moves in Bear Markets, and The Real Crash.
                  </p>
                </div>
              </div>
              <button @click="schiff = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="terpin = true" class="speaker-btn">
            <img src="../assets/speakers/Terpin.jpg" alt="Michael Terpin">
            <h2>Michael Terpin</h2>
            <h4>Transform Group, BitAngels - Founder and CEO</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="terpin" @click="terpin = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="terpin">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Terpin.jpg" alt="Michael Terpin">
                </div>
                <div class="speaker-info">
                  <h2>Michael Terpin</h2>
                  <h3>Transform Group, BitAngels - Founder and CEO</h3>
                  <p>
                    <a href="https://cointelegraph.com/news/us-blockchain-investor-terpin-awarded-over-75-million-in-sim-swapping-case" target="_blank">Michael Terpin</a>
                    is an accomplished entrepreneur, investor, and public relations
                    expert who has made significant contributions to the blockchain and cryptocurrency
                    industry. He has been an influential figure in the space since its early days
                    and has played a vital role in the development and growth of the industry.
                    <br><br>
                    Terpin began his career as the
                    <a href="https://cointelegraph.com/news/us-blockchain-investor-terpin-awarded-over-75-million-in-sim-swapping-case" target="_blank">CEO of Terpin Communications</a>
                    in 1990, which he ran until 2001 when he sold it to Morgen-Walke/Lighthouse, now Financial Dynamics.
                    In 1994, he founded Internet Wire, which became the first Internet-based company
                    newswire and was later rebranded as Marketwired. During his time as CEO of Marketwired,
                    Terpin introduced a range of innovative solutions to the PR industry, including the
                    first multi-featured online newsroom, earning him a reputation as a pioneer in the field of internet-based PR.
                    <br><br>
                    Terpin has also been an active investor in the blockchain and cryptocurrency space and has
                    made early investments in some of the industry's most successful startups, including Ethereum,
                    Factom, and Augur. He is a <a href="https://www.coindesk.com/author/michael-terpin/" target="_blank">frequent speaker at industry conferences</a>
                    and is widely regarded as an expert on blockchain, cryptocurrency, and PR.
                  </p>
                </div>
              </div>
              <button @click="terpin = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="ella = true" class="speaker-btn">
            <img src="../assets/speakers/Ella.jpg" alt="Ella Woger Nieves">
            <h2>Ella Woger Nieves</h2>
            <h4>Invest Puerto Rico - Chief Executive Officer</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="ella" @click="ella = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="ella">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Ella.jpg" alt="Ella Woger Nieves">
                </div>
                <div class="speaker-info">
                  <h2>Ella Woger Nieves</h2>
                  <h3>Invest Puerto Rico - Chief Executive Officer</h3>
                  <p>
                    A Puerto Rico native, Ella brings 20 years of experience in strategic marketing and
                    management consulting to strengthen Invest Puerto Rico's endeavors. She played an
                    instrumental role in the organization's creation, helping establish InvestPR's mission,
                    objectives, and roadmap to accomplishing them. A true believer in the island's potential as a
                    world-class investment destination, Ella ensures that all stakeholders - internal and external
                    - are aligned to InvestPR's vision of being a transformational and results-oriented accelerator
                    of economic development in Puerto Rico.
                  </p>
                </div>
              </div>
              <button @click="ella = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single lg-bio">
          <button @click="marc = true" class="speaker-btn">
            <img src="../assets/speakers/LoPresti.png" alt="Marc X. LoPresti">
            <h2>Marc X. LoPresti</h2>
            <h4>IDI Group - Founder & Managing Director</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="marc" @click="marc = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="marc">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/LoPresti.png" alt="Marc X. LoPresti">
                </div>
                <div class="speaker-info">
                  <h2>Marc X. LoPresti</h2>
                  <h3>IDI Group - Founder & Managing Director</h3>
                  <p>
                    Marc is a Wall Street veteran with over 25+ years' experience in alternative
                    investments, having started his career on the floor of the NYSE working for Lehman
                    Brothers. Marc is currently: Co-Founder & Managing Director, IDI Group (a global multi-
                    family office focused on early-stage venture investments), Co-Founder & Managing
                    Director, Moneta Advisory Partners (a “Venture Evangelism” platform), Co-Founder &
                    Board Member, BattleFin Group (a leading alternative data company), Co-Founder,
                    Revolution Media Group (producer of the popular “Bulls, Bears & Blockchain” podcast),
                    Board Member, Market Rebellion, LLC. Marc was also the founder of LoPresti Law
                    Group, LLC, a leading securities law firm in New York focused on alternative
                    investments.
                    <br><br>
                    A recognized market strategist and authority on alternative investments, Marc is a co-
                    founder of Moneta Advisory Partners, LLC, a “venture evangelism” platform that raises
                    awareness of a portfolio of growing public companies.
                    <br><br>
                    Marc appears regularly on Fox Business, CNBC, CoindeskTV, Schwab Network, Sirius
                    XM Radio, Bloomberg, and Fox. He has been quoted in numerous publications
                    including Forbes, Reuters, the Wall Street Journal, Investor's Business Daily, and
                    Barron's, and is a co-host and co-founder and co-host of proprietary programming
                    including the popular “Rebels' Edge” streaming show and the “Bulls, Bears &
                    Blockchain” radio show. He is also a frequently featured speaker on investment panels
                    at conferences around the world. Marc graduated from New York Law School in 1997.
                  </p>
                </div>
              </div>
              <button @click="marc = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="hayes = true" class="speaker-btn">
            <img src="../assets/speakers/Hayes.jpg" alt="Thomas J. Hayes">
            <h2>Thomas J. Hayes</h2>
            <h4>Great Hill Capital - Founder, Chairman & Managing Member</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="hayes" @click="hayes = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="hayes">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Hayes.jpg" alt="Thomas J. Hayes">
                </div>
                <div class="speaker-info">
                  <h2>Thomas J. Hayes</h2>
                  <h3>Great Hill Capital - Founder, Chairman & Managing Member</h3>
                  <p>
                    Thomas J. Hayes is the Founder, Chairman and Managing Member of 
                    <a href="https://www.hedgefundtips.com/money-management/" target="_blank">Great Hill Capital, LLC</a>
                    (a long/short equity manager based in New York City). He has been Featured OnFox Business TV,
                    CNBC, BBC, Yahoo! Finance TV, Wall Street Journal, Barron's, Bloomberg, CNBC Asia, New York Post, 
                    Fortune, Cheddar TV, i24News, CGTN, Financial Times,
                    Reuters, MarketWatch, U.S. News & World Report, Kiplinger, TheStreet, CGTN America, CNA Asia,
                    ZeroHedge, Inside Futures, BarChart, Seeking Alpha, Fidelity and other venues.  
                    <br><br>
                    He is the founder of top ranked podcast (in Hedge Fund category by feedspot)
                    "Hedge Fund Tips with Tom Hayes."  It can be found on YouTube or wherever you get your podcasts.
                  </p>
                </div>
              </div>
              <button @click="hayes = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="bryn = true" class="speaker-btn">
            <img src="../assets/speakers/Bryn.png" alt="Bryn Talkington">
            <h2>Bryn Talkington</h2>
            <h4>Requisite Capital Management -  Managing Partner & Co-Founder</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="bryn" @click="bryn = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="bryn">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Bryn.png" alt="Bryn Talkington">
                </div>
                <div class="speaker-info">
                  <h2>Bryn Talkington</h2>
                  <h3>Requisite Capital Management -  Managing Partner & Co-Founder</h3>
                  <p>
                    Bryn Talkington is a Managing Partner and co-founder of Requisite Capital Management.
                    Bryn has more than 25 years of experience in the financial services industry. Her areas
                    of expertise include all facets of Asset Management with a focus on Capital Markets, Alternatives and Investor behavior.
                    <br><br>
                    Bryn has spent most of her career working with private clients, foundations and endowments.
                    Prior to Requisite Capital Management, Bryn was an Executive Director at UBS Asset Management where she worked for 15 years.
                    Prior to UBS, she worked at Bear Stearns. Bryn is a CNBC Contributor and a frequent guest on “Fast Money Halftime Report”
                    and “Closing Bell.” 
                  </p>
                </div>
              </div>
              <button @click="bryn = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single lg-bio">
          <button @click="karabell = true" class="speaker-btn">
            <img src="../assets/speakers/Karabell.jpg" alt="Zachary Karabell">
            <h2>Zachary Karabell</h2>
            <h4>Progress Network at New America - Founder, River Twice Capital - President</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="karabell" @click="karabell = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="karabell">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Karabell.jpg" alt="Zachary Karabell">
                </div>
                <div class="speaker-info">
                  <h2>Zachary Karabell</h2>
                  <h3>Progress Network at New America - Founder, River Twice Capital - President</h3>
                  <p>
                    Zachary Karabell is the founder of the Progress Network at New America, president of River
                    Twice Capital, an investor in Shakespeare & Co. bookstores and other small businesses, and the
                    majority owner of Mine Hill Distillery. He is also the host of the podcast “What Could Go
                    Right?” and an author and columnist. Previously, he was Head of Global Strategies at Envestnet,
                    a publicly traded financial services firm. Prior to that, he was President of Fred Alger &
                    Company.
                    <br><br>
                    Educated at Columbia, Oxford and Harvard, where he received his Ph.D., Karabell has written
                    widely on history, economics and international relations. His most recent book, <i>Inside Money:
                    Brown Brothers Harriman and the American Way of Power,</i> was published by Penguin Press in
                    May of 2021, and his last book was <i>The Leading Indicators: A Short History of the Numbers
                    That Rule Our World.</i> His is currently writing a global history of corn. He is the author of eleven
                    previous books. He is the chairman of the Institute of Global Affairs at the Eurasia Group, and he
                    sits on the board of New America, Heyday Books and PEN America. Once upon a time, in 2003,
                    the World Economic Forum designated him a "Global Leader for Tomorrow."
                    <br><br>
                    As a commentator, Karabell is a regular columnist for <i>Time</i> and Contributing Editor for <i>Politico.</i>
                    Previously he wrote “The Edgy Optimist” column for <i>Slate, Reuters</i> and <i>The Atlantic.</i> He has
                    been a commentator on CNBC, Fox Business and MSNBC. He also contributes to such
                    publications as <i>The New York Times, The Washington Post, Wired, The Guardian, The Daily
                    Beast, The Atlantic, The Wall Street Journal, The Los Angeles Times, Foreign Policy</i> and
                    <i>Foreign Affairs.</i>
                  </p>
                </div>
              </div>
              <button @click="karabell = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="divine = true" class="speaker-btn">
            <img src="../assets/speakers/Divine.jpg" alt="John Divine">
            <h2>John Divine</h2>
            <h4>BlockFills, Digital Asset OTC Trading</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="divine" @click="divine = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="divine">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Divine.jpg" alt="John Divine">
                </div>
                <div class="speaker-info">
                  <h2>John Divine</h2>
                  <h3>BlockFills, Digital Asset OTC Trading</h3>
                  <p>
                    John James Divine is an author, speaker, market maker and liquidity provider focused on structured products,
                    derivatives, lending, borrowing, and spot market execution for digital assets. John began his
                    career as a market maker for Chicago Mercantile Exchange and Intercontinental Exchange listed
                    derivatives, specializing in precious metals, industrial metals, interest rates, energies,
                    and forward volatility curves. John has also operated within environmental products, exchange-listed,
                    and over-the-counter (OTC) for compliance and voluntary participants. 
                    <br><br>
                    John moved into the digital asset arena trading as an execution specialist for an industry-leading
                    hedge fund before joining BlockFills. He currently engages with institutional participants in digital
                    asset markets on crypto strategy development and execution. John Divine earned his Bachelor of Science
                    in Renewable Energy Technology from Illinois State University and has a Master's Degree in Energy
                    Systems and Fuels Management from Southern Illinois University, Carbondale.
                  </p>
                </div>
              </div>
              <button @click="divine = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
        <div class="speakers__cards--single">
          <button @click="gonta = true" class="speaker-btn">
            <img src="../assets/speakers/Gonta.png" alt="Igor Gonta">
            <h2>Igor Gonta</h2>
            <h4>Market Prophit, CEO<br>
              RWA.Holdings, CEO/Co-Founder<br>
              Former Blackrock Alternative Investors</h4>
          </button>
          <transition name="fade" appear>
            <div class="modal-overlay" v-if="gonta" @click="gonta = false"></div>
          </transition>
          <transition name="pop" appear>
            <div class="modal" role="dialog" v-if="gonta">
              <div class="speaker-modal">
                <div class="speaker-thumbnail">
                  <img src="../assets/speakers/Gonta.png" alt="Igor Gonta">
                </div>
                <div class="speaker-info">
                  <h2>Igor Gonta</h2>
                  <h3>
                    Market Prophit, CEO<br>
                    RWA.Holdings, CEO/Co-Founder<br>
                    Former Blackrock Alternative Investors
                  </h3>
                  <p>
                    Igor spent his career on Wall Street in commodities markets. Most recently he was at BlackRock serving
                    as the Global Head of Commodities Risk Management at BlackRock Alternative Investors.
                    He started his career on Wall Street at Goldman Sachs and Barclays.
                    <br><br>
                    Igor is an entrepreneur and an active investor and advisor to technology startups in areas focused on Bitcoin mining,
                    cryptocurrencies, blockchain and Web 3.0 companies, disruptive energy technology, AI, Machine Learning, and Fintech.
                    He has been an investor and a trader of cryptocurrencies since 2016.
                  </p>
                </div>
              </div>
              <button @click="gonta = false" class="close">⨉</button>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/speakers.scss';
</style>

<script>

export default {
  name: 'Speakers',

  data() {
    return {
      pierce: false,
      jon: false,
      terpin: false,
      putra: false,
      marc: false,
      pham: false,
      schiff: false,
      hayes: false,
      karabell: false,
      divine: false,
      ella: false,
      bryn: false,
      gonta: false
    };
  },
};
</script>