<template>
  <Menu buttonText="Register for 2025" />
  <main id="home">
    <hero />
    <hotel />
    <speakers />
    <location />
    <companypass />
    <!--<presenters />-->
    <!--<sponsors />-->
  </main>
</template>

<style lang="scss" scoped>
  @import '../styles/pages/home.scss';
</style>

<script>
// @ is an alias to /src
import Menu from '@/components/menu';
import hero from '@/components/hero.vue';
import hotel from '@/components/hotel.vue';
import location from '@/components/location.vue';
import companypass from '@/components/companypass.vue';
//import sponsors from '@/components/sponsors.vue';
//import presenters from '@/components/presenters.vue';
import speakers from '@/components/speakers.vue';

export default {
  name: 'Home',
  data() {
    return {
    };
  },
  components: {
    Menu,
    hero,
    hotel,
    location,
    //sponsors,
    companypass,
    //presenters,
    speakers
  },
};
</script>
